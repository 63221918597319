.active-menu {
  background-color: #800080 !important; /* Red Background */
  color: white !important; /* Ensure text is visible */
  border-radius: 5px;
  padding: 10px;
}
/* Hide sidebar on mobile */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .bottom-nav {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid #ddd;
    padding: 10px 0;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  }

  .bottom-nav ul {
    display: flex;
    width: 100%;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .bottom-nav .nav-item {
    text-align: center;
    flex-grow: 1;
  }

  .bottom-nav .nav-item a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom-nav .nav-item a svg {
    margin-bottom: 5px;
  }
}
