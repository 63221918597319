.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: #333;
}
.close-btn:hover {
  color: red;
}
